<template>
    <v-container>
        <v-layout column class="panel-wrapper ma-5 pa-5" style="background-color: #fff">
            <v-flex xs12 style="text-align: center">
                <div>
                    <img src="./_source/processing.png" alt="Pending Client" style="width: 150px;height: 150px;">
                </div>
                <h1 class="my-3">Identity Verification</h1>
                <div>
                    <p>For security reasons, we need to carry out our identity verification process manually before the service. It usually takes 1-2 hours. Thanks for your patience.</p>
                </div>
                <v-btn class="my-3 primary" to="/applications">Check My Application</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "WaitVerify",
    }
</script>

