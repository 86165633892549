var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        {
          staticClass: "panel-wrapper ma-5 pa-5",
          staticStyle: { "background-color": "#fff" },
          attrs: { column: "" }
        },
        [
          _c(
            "v-flex",
            { staticStyle: { "text-align": "center" }, attrs: { xs12: "" } },
            [
              _c("div", [
                _c("img", {
                  staticStyle: { width: "150px", height: "150px" },
                  attrs: {
                    src: require("./_source/processing.png"),
                    alt: "Pending Client"
                  }
                })
              ]),
              _c("h1", { staticClass: "my-3" }, [
                _vm._v("Identity Verification")
              ]),
              _c("div", [
                _c("p", [
                  _vm._v(
                    "For security reasons, we need to carry out our identity verification process manually before the service. It usually takes 1-2 hours. Thanks for your patience."
                  )
                ])
              ]),
              _c(
                "v-btn",
                { staticClass: "my-3 primary", attrs: { to: "/applications" } },
                [_vm._v("Check My Application")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }